
import MedicalIcon from "@/components/icon/MedicalIcon.vue";
import { defineComponent, onBeforeMount, onMounted, ref } from "vue";
import formHttp from "@/http/formHttp";
import useStep2Form from "@/forms/useStep2Form";
import { useSubscriber } from "@/use/useSubscriber";
import { useRouter } from "vue-router";
import { useLoader } from "@/use/useLoader";

export default defineComponent({
  components: { MedicalIcon },
  setup() {
    const { v$, fields } = useStep2Form();
    const router = useRouter();
    const subscriber = useSubscriber();
    const showAlert = ref<boolean>(false);
    const { isLoading, addLoading, removeLoading } = useLoader();

    onBeforeMount(() => {
      if (subscriber.getCurrentStep() < 2) {
        return router.push({ name: "FormSubmission" });
      }
    });

    onMounted(async () => {
      try {
        const { data } = await formHttp.getStep2();
        Object.assign(fields, data.content);
      } catch ({ response }) {
        showAlert.value = true;
      }
    });

    const onSubmit = async () => {
      v$.value.$touch();

      if (v$.value.$invalid) return;

      try {
        addLoading();
        await formHttp.postStep2(fields);
        await subscriber.refreshCurrentStep();
        await formHttp.postStep3();
        router.push({ name: "Confirmed" });
      } catch ({ response }) {
        showAlert.value = true;
      }

      removeLoading();
    };

    const onCaptchaVerified = (recaptchaToken: string) => {
      fields.recaptcha = recaptchaToken;
    };

    const onCaptchaExpired = () => {
      fields.recaptcha = "";
    };

    return {
      v$,
      fields,
      ref,
      showAlert,
      onSubmit,
      onCaptchaVerified,
      onCaptchaExpired,
      isLoading,
    };
  },
});
